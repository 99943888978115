/* istanbul ignore file */
import {
  BookingCreateApi,
  BookingParams,
  BulkAssignBookingUpdate,
  BulkAssignVerifyApi,
  IBookingRepository,
  IBookingUpdate,
} from '@common/interfaces';
import fileDownload from 'js-file-download';
import AxiosRepository from './axios.repository';

class BookingRepository extends AxiosRepository implements IBookingRepository {
  private buildParams(params?: BookingParams) {
    const searchParams = new URLSearchParams();

    if (params?.agencyId) searchParams.append('agency', params.agencyId);
    if (params?.dateAfter) searchParams.append('pickup_date_after', params.dateAfter);
    if (params?.dateBefore) searchParams.append('pickup_date_before', params.dateBefore);
    if (params?.ids) searchParams.append('id__in', params.ids);
    if (params?.limit) searchParams.append('limit', String(params.limit));
    if (params?.offset) searchParams.append('offset', String(params.offset));
    if (params?.ordering) searchParams.append('ordering', params.ordering);
    if (params?.rangeEnd) searchParams.append('range_end', params.rangeEnd);
    if (params?.rangeStart) searchParams.append('range_start', params.rangeStart);
    if (params?.search) searchParams.append('search', params.search);

    if (params?.filters && Object.keys(params?.filters).length) {
      Object.entries(params?.filters).forEach(([key, value]) => {
        searchParams.append(key, String(value));
      });
    }

    return searchParams;
  }

  async createBooking(facility: string, payload: BookingCreateApi) {
    const { data } = await this.post(`/facilities/${facility}/bookings/web/form/`, payload);
    return data;
  }

  async getBookingDetails(facility: string, bookingId: string) {
    const { data } = await this.get(`/facilities/${facility}/bookings/${bookingId}/`);
    return data;
  }

  async getBookings(facility: string, params?: BookingParams) {
    const query = this.buildParams(params);
    const { data } = await this.get(`/facilities/${facility}/bookings/${params?.show}/?${query}`);
    return data;
  }

  async getDriverBookingSchema(facility: string) {
    const { data } = await this.get(`/facilities/${facility}/bookings/web/form/`);
    return data;
  }

  async getFilters(facility: string, params?: BookingParams) {
    const query = this.buildParams(params);
    const { data } = await this.get(`/facilities/${facility}/bookings/filters/?${query}`);
    return data;
  }

  async getShuttleRoutes(facility: string, params?: BookingParams) {
    const query = this.buildParams(params);
    const { data } = await this.get(`/facilities/${facility}/bookings/shuttle-routes/?${query}`);
    return data;
  }

  async exportBookings(facility: string, params?: BookingParams) {
    const query = this.buildParams(params);
    const { data, headers } = await this.get(`/facilities/${facility}/bookings/export/?${query}`, {
      responseType: 'blob',
    });

    const filename = headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].split(';')[0]
      : '';

    fileDownload(new File([data], filename), filename);

    return data;
  }

  async forwardBooking(facility: string, agencyId: string, bookingId: string) {
    const { data } = await this.post(
      `/facilities/${facility}/bookings/${bookingId}/forward/${agencyId}/`,
      {},
    );
    return data;
  }

  async rejectBooking(facility: string, agency: string, booking: string, payload: IBookingUpdate) {
    const { data } = await this.patch(
      `/facilities/${facility}/bookings/${booking}/?agency=${agency}`,
      payload,
    );
    return data;
  }

  async updateBooking(facility: string, bookingId: string, payload: IBookingUpdate) {
    const { data } = await this.patch(`/facilities/${facility}/bookings/${bookingId}/`, payload);
    return data;
  }

  async updateBookingsWithBulkAssign(facility: string, payload: BulkAssignBookingUpdate[]) {
    const { data } = await this.post(
      `/facilities/${facility}/bookings/shuttle-bookings/bulk-assign/`,
      payload,
    );
    return data;
  }

  async verifyBulkAssign(facility: string, payload: BulkAssignVerifyApi) {
    const { data } = await this.post(
      `/facilities/${facility}/bookings/shuttle-bookings/bulk-assign/verify/`,
      payload,
    );
    return data;
  }
}

export default BookingRepository;
