import { type FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '@unbooking/ui-modules';
import { Loader } from '@components';
import { useFacility } from '@common/hooks';
import { useRepository } from '@context';
import { IDriveCosts } from '@common/interfaces';
import { Serializer } from '@common/utils';
import { CostsSettingsAdd } from '@assets/svg/icons';
import { DriveCosts } from './components';
import './styles.scss';

const ServiceCostSettingsPage: FC = () => {
  const { t } = useTranslation();
  const { financialRepo } = useRepository();
  const { agencyName, facility, facilityId } = useFacility();
  const { agencyId, city, country } = facility;

  const [driveCosts, setDriveCosts] = useState<IDriveCosts[]>([]);

  const { isLoading } = useQuery(
    'get-drive-costs',
    () => financialRepo.getDriveCosts(facilityId, { agencyId }),
    {
      enabled: !!agencyId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) setDriveCosts(data.results.map(Serializer.formatDriveCosts));
      },
    },
  );

  return (
    <section className="hbh-container service-cost-settings-page">
      <PageTitle
        title={`${t('mobility.serviceCostSettingsTitle')} ${city}, ${country} | ${agencyName}`}
        bottomLine
      />

      <Loader fullScreen spinning={isLoading} />

      <div className="cost-settings">
        <div className="cost-settings-header">
          <h3 className="cost-settings-header-title">
            <CostsSettingsAdd />
            {t('mobility.fleetCostsSettings')}
          </h3>
        </div>

        {driveCosts.length > 0 ? (
          driveCosts?.map((item: IDriveCosts) => <DriveCosts data={item} key={item.id} />)
        ) : (
          <div className="cost-settings-empty">{t('mobility.noCostSettings')}</div>
        )}
      </div>
    </section>
  );
};

export default ServiceCostSettingsPage;
