import { type FC, Fragment, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { format, isBefore, parse } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { Button, DetailsContainer, PageTitle } from '@unbooking/ui-modules';
import type { BookingDetails, IBookingUpdate, BDRoute } from '@common/interfaces';
import { DriveStatus, BookingState } from '@common/interfaces';
import { Loader } from '@components';
import { useRepository } from '@context';
import { useFacility } from '@common/hooks';
import { DateFormat as DF, StatusCode } from '@common/types';
import { formatDateString, Serializer } from '@common/utils';
import { ArrowRound, File } from '@assets/svg/icons';
import './styles.scss';

const sortRoutes = (a: BDRoute, b: BDRoute) => {
  const firstRoute = parse(`${a.pickupDate} ${a.pickupTime}`, DF.ApiDateFull, new Date());
  const secondRoute = parse(`${b.pickupDate} ${b.pickupTime}`, DF.ApiDateFull, new Date());
  return isBefore(firstRoute, secondRoute) ? -1 : 1;
};

const sliceIntoChunks = (arr: any[], chunkSize: number) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

const BookingDetailsPage: FC = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id: bookingId } = useParams<{ id: string }>();
  const { bookingRepository } = useRepository();
  const { agencyName, facility, facilityId } = useFacility();
  const { city, country } = facility;
  const locale = i18n.language === 'fr' ? fr : enUS;

  const [booking, setBooking] = useState<BookingDetails>();
  const [isOpenRequestorPanel, setIsOpenRequestorPanel] = useState<boolean>(false);
  const [isOpenOtherPassengersPanel, setIsOpenOtherPassengersPanel] = useState<boolean>(false);

  const { isLoading, refetch: getBookingDetails } = useQuery(
    `booking-details-${bookingId}`,
    () => bookingRepository.getBookingDetails(facilityId, bookingId!),
    {
      retry: false,
      onSuccess: (data) => {
        if (data) setBooking(Serializer.formatBookingDetails(data));
      },
      onError: (error: any) => {
        if (error?.response?.status === StatusCode.ClientErrorNotFound) {
          toast.error(t('bookingDetails.errorMsg'));
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        }
      },
    },
  );

  const bookingMutate = useMutation<unknown, AxiosError, IBookingUpdate>(
    'update-booking',
    (data) => bookingRepository.updateBooking(facilityId, booking?.id!, data),
    {
      onSuccess: () => {
        toast.success(t('bookingDetails.msgUpdateBooking'));
        getBookingDetails();
      },
      onError: (e) => {
        if (e.message) toast.error(t('common.errorMsgDefault'));
      },
    },
  );

  const { mutateAsync: updateBooking, isLoading: isBookingProcessing } = bookingMutate;

  const getBookingStateLabel = (data: BookingDetails): JSX.Element => (
    <div className={`booking-label btn-${data.state?.replace('_', '-')}`}>
      {`${t('common.booking')} ${data.stateDisplay}`}
    </div>
  );
  const isBookingEditable =
    booking?.state === BookingState.Accepted ||
    (booking?.state === BookingState.Ongoing &&
      booking?.drives.find((i) => i.state === DriveStatus.NotStarted));
  const isMultiLeg = booking?.typeOfTrip === 'multi-leg';
  const isNotProcessed = booking?.state === BookingState.NotProcessed;

  return (
    <>
      <PageTitle
        className="booking-details-title"
        title={
          city && country ? `${t('bookingDetails.title')}, ${city}, ${country} | ${agencyName}` : ''
        }
      />

      <Loader spinning={isLoading || isBookingProcessing}>
        <section className="hbh-container booking-details-container">
          <div className="title">
            <div>{booking ? getBookingStateLabel(booking) : null}</div>
          </div>

          <DetailsContainer
            className="asset-details"
            divider
            items={[
              {
                label: t('booking.refCode'),
                content: booking?.refCode ?? '',
                className: 'row-1-col-1',
              },
              {
                label: t('booking.bookingRequest'),
                content: booking?.dispatchDate ?? '',
                className: 'row-1-col-2',
              },
              {
                label: t('common.firstName'),
                content: booking?.firstName ?? '',
                className: 'row-1-col-3',
              },
              {
                label: t('common.lastName'),
                content: booking?.lastName ?? '',
                className: 'row-1-col-4',
              },
              {
                label: t('common.pax'),
                content: String(booking?.pax ?? ''),
                className: 'row-2-col-1',
              },
              {
                label: t('common.agency'),
                content: booking?.agencyFk?.shortName ?? '',
                className: 'row-2-col-2',
              },
              {
                label: t('common.phoneNumber'),
                content: booking?.phoneNumber ?? '',
                className: 'row-2-col-3',
              },
              {
                label: t('common.email'),
                content: booking?.email ?? '',
                className: 'row-2-col-4',
              },
              {
                label: t('common.indexNumber'),
                content: booking?.indexNumber ?? '',
                className: 'row-3-col-1',
              },
              {
                label: t('common.requestingUnit'),
                content: booking?.requestingUnitDisplay ?? '',
                className: 'row-3-col-2',
              },
              {
                label: t('common.typeOfTrip'),
                content: booking?.typeOfTripDisplay ?? '',
                className: 'row-3-col-3',
              },
              {
                label: t('common.transferType'),
                content: booking?.transferTypeDisplay ?? '',
                className: 'row-3-col-4',
              },
              ...(booking?.transferType === 'airport'
                ? [
                    {
                      label: t('common.flightArrivalDepartureTime'),
                      content: booking?.flightArrivalDepartureTime
                        ? formatDateString(
                            booking?.flightArrivalDepartureTime,
                            DF.ApiTimeFull,
                            DF.ApiTime,
                          )
                        : '',
                      className: 'row-4-col-1',
                    },
                    {
                      label: t('common.flightNumber'),
                      content: booking?.flightNumber ?? '',
                      className: 'row-4-col-2',
                    },
                  ]
                : []),
              ...(booking?.remarks
                ? [
                    {
                      label: t('common.remarks'),
                      content: booking?.remarks,
                      className: 'row-5-col-1 line-break',
                    },
                  ]
                : []),
            ]}
          />

          {booking?.recurringData ? (
            <DetailsContainer
              className="recurring-drive"
              title={t('common.recurring.drive')}
              items={[
                {
                  label: t('common.recurring.isRecurring'),
                  content: t('common.yes'),
                },
                {
                  label: t('common.recurring.type'),
                  content: `${booking.recurringData.frequencyDisplay} ${
                    booking.recurringData.frequencyDisplay === 'Daily'
                      ? `(${booking.recurringData.weekdaysDisplay})`
                      : ''
                  }`,
                },
                {
                  label: t('common.inputStartDate'),
                  content: format(new Date(booking.recurringData.startDate), DF.BookingInfoDate, {
                    locale,
                  }),
                },
                {
                  label: t('common.inputEndDate'),
                  content: format(new Date(booking.recurringData.endDate), DF.BookingInfoDate, {
                    locale,
                  }),
                },
              ]}
            />
          ) : null}

          {booking?.routes?.sort(sortRoutes).map((route: BDRoute, idx: number) => (
            <div className="booking-route" key={route.id}>
              <DetailsContainer
                className={`route-details ${!route.isActive ? 'route-canceled' : ''}`}
                items={[
                  {
                    label: t('bookingDetails.pickupDate'),
                    content: route.pickupDate
                      ? formatDateString(route.pickupDate, 'yyyy-MM-dd', 'dd MMM yyyy')
                      : '',
                    className: 'row-1-col-1',
                  },
                  {
                    label: t('bookingDetails.pickupTime'),
                    content: route.pickupTime ?? '',
                    className: 'row-1-col-2',
                  },
                  {
                    label: t('bookingDetails.dropoffDate'),
                    content: route.dropoffDate
                      ? formatDateString(route.dropoffDate, 'yyyy-MM-dd', 'dd MMM yyyy')
                      : '',
                    className: 'row-1-col-3',
                  },
                  {
                    label: t('bookingDetails.dropoffTime'),
                    content:
                      route.dropoffTime && booking?.state !== BookingState.NotProcessed
                        ? route.dropoffTime
                        : '',
                    className: 'row-1-col-4',
                  },
                  {
                    label: t('bookingDetails.pickupTown'),
                    content: route.pickupTown ?? '',
                    className: 'row-2-col-1',
                  },
                  {
                    label: t('bookingDetails.pickupLocation'),
                    content: route.pickupLocation ?? '',
                    className: 'row-2-col-2',
                  },
                  {
                    label: t('bookingDetails.dropoffTown'),
                    content: route.dropoffTown ?? '',
                    className: 'row-3-col-1',
                  },
                  {
                    label: t('bookingDetails.dropoffLocation'),
                    content: route.dropoffLocation ?? '',
                    className: 'row-3-col-2',
                  },
                  ...(!isNotProcessed
                    ? [
                        {
                          label: t('common.driver'),
                          content: booking?.drives[idx]?.driver ?? '',
                          className: 'row-4-col-1',
                        },
                        {
                          label: t('mobility.plateNumber'),
                          content: booking?.drives[idx]?.vehicle ?? '',
                          className: 'row-4-col-2',
                        },
                      ]
                    : []),
                ]}
                title={
                  isMultiLeg
                    ? `${t('bookingDetails.leg')} ${idx + 1}`
                    : `${
                        idx === 0 ? t('bookingDetails.outwardTrip') : t('bookingDetails.returnTrip')
                      }`
                }
              />
            </div>
          ))}

          <div className="booking-requestor-details">
            <div className="details-panel">
              <span>{t('bookingDetails.showBookingRequestorDetails')}</span>
              <Button
                className={isOpenRequestorPanel ? 'btn-requestor open' : 'btn-requestor'}
                icon={<ArrowRound />}
                variant="icon"
                onClick={() => setIsOpenRequestorPanel(!isOpenRequestorPanel)}
              />
            </div>
            <div className={isOpenRequestorPanel ? 'panel-content open' : 'panel-content'}>
              <DetailsContainer
                className="asset-requestor"
                items={[
                  {
                    label: t('common.username'),
                    content: booking?.bookingAgentUsername ?? '',
                    className: 'col-1',
                  },
                  {
                    label: t('common.email'),
                    content: booking?.bookingAgentEmail ?? '',
                    className: 'col-2',
                  },
                  {
                    label: t('common.firstName'),
                    content: booking?.bookingAgentFirstName ?? '',
                    className: 'col-3',
                  },
                  {
                    label: t('common.lastName'),
                    content: booking?.bookingAgentLastName ?? '',
                    className: 'col-4',
                  },
                ]}
                title={t('bookingDetails.bookingRequestor')}
              />
            </div>
          </div>

          {!!booking?.passengersInfo?.length && (
            <div className="booking-passengers-details">
              <div className="details-panel">
                <span>Show other passengers details</span>
                <Button
                  className={isOpenOtherPassengersPanel ? 'btn-requestor open' : 'btn-requestor'}
                  data-testid="btn-show-other-passengers"
                  icon={<ArrowRound />}
                  variant="icon"
                  onClick={() => setIsOpenOtherPassengersPanel(!isOpenOtherPassengersPanel)}
                />
              </div>

              <div className={isOpenOtherPassengersPanel ? 'panel-content open' : 'panel-content'}>
                {booking.passengersInfo.map((passenger, idx: number) => (
                  <Fragment key={passenger.email}>
                    <DetailsContainer
                      title={`Passenger ${idx + 2}`}
                      items={[
                        {
                          label: 'First Name',
                          content: passenger.firstName,
                        },
                        {
                          label: 'Last Name',
                          content: passenger.lastName,
                        },
                        {
                          label: 'Email',
                          content: passenger.email,
                        },
                      ]}
                    />
                    <DetailsContainer
                      items={[
                        {
                          label: 'Agency',
                          content: passenger.agency,
                        },
                        {
                          label: 'Phone number',
                          content: passenger.phoneNumber,
                        },
                        {
                          label: 'Requesting unit',
                          content: passenger.requestingUnitDisplay,
                        },
                      ]}
                    />
                  </Fragment>
                ))}
              </div>
            </div>
          )}

          {!!booking?.attachments?.length &&
            sliceIntoChunks(booking.attachments, 2).map((chunk, i) => (
              <DetailsContainer
                title={i === 0 ? 'Attachments' : undefined}
                className="attachments"
                items={chunk.map((attachment, j) => ({
                  label: '',
                  content: (
                    <a className="attachment" href={attachment.attachmentLink}>
                      <File />
                      <h5>Attachment {i * 2 + j + 1}</h5>
                    </a>
                  ),
                }))}
              />
            ))}

          {booking ? (
            <div className="footer">
              {getBookingStateLabel(booking)}
              <div className="buttons">
                {isBookingEditable && (
                  <Button
                    className="btn btn-edit"
                    text={t('common.btnEdit')}
                    variant="primary"
                    onClick={() => navigate(`edit-drives/${booking.routes[0].driveId}`)}
                  />
                )}

                {booking?.state === 'accepted' && (
                  <Button
                    className="btn btn-cancel"
                    text={t('common.btnCancel')}
                    variant="danger"
                    onClick={() => {
                      if (!isBookingProcessing) {
                        updateBooking({
                          state: BookingState.Cancelled,
                        });
                      }
                    }}
                  />
                )}

                <Button
                  className="btn btn-back"
                  text={t('common.btnBackToList')}
                  variant="primary"
                  onClick={() => navigate(-1)}
                />
              </div>
            </div>
          ) : null}
        </section>
      </Loader>
    </>
  );
};

export default BookingDetailsPage;
