/* istanbul ignore file */
export const TRANSLATIONS_FR = {
  booking: {
    accepted: 'Accepté',
    bookingRequest: 'Demande de réservation',
    cancelledByFP: 'Annulé par FP',
    finished: 'Finie',
    msgBookingAccepted: 'La réservation a déjà été acceptée',
    msgBookingRejected: 'La réservation a été rejetée',
    notProcessed: 'Non traité',
    ongoing: 'En cours',
    pickup: 'Ramasser',
    process: 'Processus',
    processed: 'Traité',
    refCode: 'Code de réf',
    rejected: 'Refusé',
    request: 'Demande',
    time: 'Temps',
    userCancelled: 'Utilisateur annulé',
  },
  bookingDetails: {
    bookingRequestor: 'Demandeur de réservation',
    btnConfirmAccept: "Confirmer l'acceptation",
    btnReject: 'Rejeter',
    btnShowPreview: "Afficher l'aperçu",
    chooseTime: "Choisissez l'heure",
    dropoffAddress: 'Adresse de dépôt',
    dropoffAt: 'Dépôt à',
    dropoffDate: 'Date de dépôt',
    dropoffLocation: 'Lieu de dépôt',
    dropoffLocExtra: 'Dépôt (supplémentaire)',
    dropoffTime: 'Heure de dépôt',
    dropoffTown: 'Ville de dépôt',
    errorMsg: "Les détails de la réservation n'ont pas été trouvés",
    estimatedByGoogle: 'Estimé par Google',
    leg: 'Jambe',
    msgConflictDrives: "possède d'autres lecteurs pour cette période. Sélectionner autre",
    msgConflictMulti:
      "possède d'autres lecteurs pour cette période. Veuillez sélectionner un autre pilote",
    msgDriveAccepted: 'Drive a déjà été accepté',
    msgInvalidTime:
      "L'heure de dépôt ne peut pas être antérieure ou identique à l'heure de prise en charge",
    msgUpdateBooking: 'La réservation a été mise à jour avec succès',
    outward: "Vers l'extérieur",
    outwardTrip: 'Voyage aller',
    pickupAddress: 'Adresse de retrait',
    pickupAt: 'Ramassage à',
    pickupDate: 'Date de ramassage',
    pickupLocation: 'Lieu de ramassage',
    pickupLocExtra: 'Ramassage (supplémentaire)',
    pickupTime: 'Heure de prise en charge',
    pickupTown: 'Ville de ramassage',
    processTrip: 'Déclenchement de processus',
    return: 'Revenir',
    returnTime: 'Heure de retour',
    returnTrip: 'Voyage de retour',
    selected: 'Choisie',
    showBookingRequestorDetails: 'Afficher les détails du demandeur de réservation',
    signatureCode: 'Code-signature',
    step1: 'Étape 1 : Traiter le voyage aller',
    step2: 'Étape 2 : Traiter le voyage de retour',
    title: 'Les détails de réservation',
    viewBookingDetails: 'Afficher les détails de la réservation',
  },
  bookingForm: {
    agentDetails: "Détails de l'agent",
    attachment: 'Pièce jointe',
    attachments: 'Ajouter des pièces jointes supplémentaires (facultatif)',
    bookerDetails: 'Détails du réservateur',
    bookingPassengerDetails: 'Détails du passager de réservation',
    bookingRecap: 'Récapitulatif de la réservation',
    bookingType: 'Type de réservation',
    btnConfirmBooking: 'Confirm booking',
    btnGoBack: 'Revenir pour modifier',
    btnHomePage: "Page d'accueil",
    details: 'Détails',
    drive: 'Conduire',
    extraInformation: 'Extra information',
    managerEmail: 'Email de votre superviseur',
    msgCopySentToEmail: 'Vous recevrez une copie de votre demande à',
    msgNoRoutes: 'Aucun itinéraire trouvé pour cette date',
    msgSuccess: 'Votre demande a été envoyée avec succès',
    pax: 'Nombre de passagers',
    requestYourBooking: 'Demandez votre réservation',
    recurringDrive: 'Conduite récurrente',
    routeDetails: "Détails de l'itinéraire",
    selectTripType: 'Sélectionnez le type de voyage',
    tabColleague: "Cette réservation est pour quelqu'un d'autre",
    tabSelf: 'Cette réservation est pour moi',
    title: 'Réservation pour',
    tooltipAddress: 'Aucune adresse trouvée par Google, veuillez préciser votre adresse ci-dessous',
    tooltipGoogleLocation:
      "Saisissez et sélectionnez l'emplacement souhaité avec Google Maps. Si nécessaire, indiquez votre adresse exacte dans le champ d'adresse supplémentaire du formulaire",
    traveller: 'Voyageur',
    travellerDetails: 'Détails du voyageur',
    type: 'Taper',
    your: 'Ton',
  },
  bookingList: {
    bookingForwarded: 'Réservation transmise',
    btnExport: 'Sélectionnez',
    btnExportCurrentView: 'Vue actuelle',
    errorMsg: 'Pas de réservation pour cette période',
    filterLabelPax: 'Filtrer par Agence Pax',
    filterLabelStates: 'Filtrer par statut',
    inputSearch: 'Recherche par e-mail ou code de réf',
    modalTitle: 'Choisissez la gamme export sur demande de réservation',
    msgModalExport: 'Vous pouvez télécharger la vue actuelle',
    optionalAttachments: 'avec pièces jointes en option',
    selectedShuttle: 'réservations de navette sélectionnées',
    title: 'Réservation à traiter pour',
  },
  checklist: {
    checkInformation: 'Vérifier les informations',
    remarksFromManager: 'Remarques du responsable du transport',
    statusChecklist: "Liste de contrôle d'état",
    unknownDriver: 'Conducteur inconnu',
  },
  common: {
    agencies: 'Agences',
    agency: 'Agence',
    agencyName: "Nom d'agence",
    agencyPax: 'Agence Pax',
    agencySelectLabel: 'Vous visitez ceci en tant que',
    airport: 'Aéroport',
    allAgencies: 'Toutes les agences',
    allMonths: 'Tous les mois',
    allStatuses: 'Tous les statuts',
    allYears: 'Toutes les années',
    amount: 'Montant',
    armoured: 'Blindé',
    averageRating: 'Note moyenne',
    booking: 'Réservation',
    btnApply: 'Appliquer',
    btnBack: 'Retour',
    btnBackToList: 'Retour à la liste',
    btnBackToPlanner: 'Retour au planificateur',
    btnCalculateNow: 'Calculez maintenant',
    btnCancel: 'Annuler',
    btnCancelDrive: 'Annuler le lecteur',
    btnChange: 'Changer',
    btnClearAll: 'Tout effacer',
    btnConfirm: 'Confirmer',
    btnConfirmChanges: 'Confirmer les modifications',
    btnConfirmDates: 'Confirmer les dates',
    btnDone: 'Done',
    btnDownload: 'Télécharger',
    btnDownloadInvoice: 'Télécharger la facture',
    btnEdit: 'Éditer',
    btnExport: 'Exporter',
    btnExportData: 'Exporter des données',
    btnNext: 'Prochaine',
    btnResetFilters: 'Réinitialiser les filtres',
    btnSave: 'Sauver',
    btnVerify: 'Vérifier',
    btnView: 'Voir',
    budgetCode: 'Code budgétaire',
    carpooled: 'En covoiturage',
    city: 'Ville',
    click: 'Cliquez sur',
    costRecoveryReport: 'Rapport de recouvrement',
    country: 'Pays',
    currency: 'Devise',
    customerSatisfaction: 'Satisfaction du client',
    daily: 'Quotidiennement',
    delete: 'Effacer',
    description: 'La description',
    disable: 'Désactiver',
    documents: 'Documents',
    downloadCSV: 'Télécharger CSV',
    driver: 'Chauffeur',
    drivers: 'Conducteurs',
    dropoffTimeAirport: 'Temps de vol (arrivée)',
    duration: 'Durée',
    editDrive: 'Modifier le lecteur',
    email: 'E-mail',
    emailAddress: 'Adresse email',
    emptyList: 'Liste vide',
    errorMsgDefault: "Quelque chose s'est mal passé",
    errorMsgDateRange: "La plage de dates spécifiée n'est pas valide",
    errorMsgGps: 'Impossible de se connecter!',
    fieldRequired: 'Ce champ est requis',
    filters: 'Filtres',
    financials: 'Finances',
    firstName: 'Prénom',
    fleetManagement: 'Gestion de flotte',
    flightArrivalDepartureTime: "Heure d'arrivée du vol",
    flightNumber: 'Numéro de vol',
    from: 'de',
    fromIncluded: 'A partir de (inclus)',
    greenFund: 'Fonds vert',
    indexNumber: "Numéro d'index",
    info: 'Info',
    inputEndDate: 'Date de fin',
    inputEndTime: 'Heure de fin',
    inputSelectDays: 'Sélectionnez une plage de jours',
    inputStartDate: 'Date de début',
    inputStartTime: 'Heure de début',
    inTown: 'En ville',
    lastName: 'Nom de famille',
    lastRatings: 'Notes recentes',
    lastUpdate: 'Dernière mise à jour',
    location: 'Emplacement',
    managerEmail: 'Courriel du gestionnaire',
    manuals: 'Manuels',
    mobilityPlanner: 'Planificateur de mobilité',
    mobilityLogbook: 'Journal de mobilité',
    mobilityLogbookDetails: 'Détails page de voyage',
    month: 'Mois',
    msgErrorExport: "Désolé, l'exportation avec les filtres sélectionnés n'est pas possible",
    myMobility: 'Ma Mobilité',
    name: 'Nom',
    no: 'Non',
    note: 'Remarque',
    number: 'Numéro',
    optional: 'facultative',
    outOfTown: 'En dehors de la ville',
    passenger: 'Passagère',
    passengers: 'Passagères',
    pax: 'Pax',
    paxAgency: 'Agence Pax',
    paymentMethod: 'Mode de paiement',
    phoneNumber: 'Numéro de téléphone',
    photo: 'Photo',
    price: 'Prix',
    purpose: 'Objectif',
    rangeEnd: 'Fin de gamme',
    rangeStart: 'Début de gamme',
    recurring: {
      addRecurring: 'Ajouter un tour récurrent',
      addRecurringBtn: 'Ajouter une récurrence',
      allDays: 'Tous les jours',
      cancelRecurringBtn: 'Annuler le récurrent',
      conflictMsg1: 'Ce tour récurrent est en conflit avec un autre tour ',
      conflictMsg2: 'créé pour la même période.',
      dailyFrequency: 'Fréquence quotidienne',
      dailyRecurringDrive: 'Tour quotidien récurrent',
      drive: 'Tour récurrent',
      drivesOverview: 'Les tours suivants seront créés',
      forceCreate: {
        adviceMsg:
          "Vous pouvez procéder à la création de ce récurrent, mais il est ensuite recommandé d'attribuer le tour en conflit à un autre chauffeur.",
        conflictingDrives:
          'Il y a des entraînements contradictoires pour la période récurrente sélectionnée.',
        continuePrompt:
          'Cliquez sur OUI si vous souhaitez toujours créer un tour récurrent ou cliquez sur NON pour le rejeter.',
        title: 'Créer un tour récurrent',
      },
      isRecurring: 'Est récurrent',
      onlyWorkingDays: 'Uniquement les jours ouvrables',
      repeatDrive: 'Répéter ce tour',
      repeatUntil: "Jusqu'au (max 30 jours à compter de la date de prise en charge)",
      successMsg: 'Le tour récurrent a été créé avec succès',
      type: 'Type de récurrence',
      weeklyRecurringDrive: 'Tour hebdomadaire récurrent',
      workingDaysFromMonday: 'Uniquement les jours ouvrables (lundi - vendredi)',
      workingDaysFromSunday: 'Uniquement les jours ouvrables (dimanche - jeudi)',
    },
    recurringDrive: 'Tour récurrent',
    refCode: 'Code de réf',
    remarks: 'Remarques',
    reportingArea: 'Zone de rapport',
    requestingUnit: 'Unité requérante',
    rideshared: 'Covoiturage',
    sameDay: 'Même jour',
    select: 'Sélectionner',
    serviceCostSettings: 'Paramètres de coût de service',
    showAll: 'Afficher tout',
    soft: 'Doux',
    status: 'Statut',
    surname: 'Nom de famille',
    to: 'à',
    toIncluded: 'À (inclus)',
    total: 'Totale',
    town: 'Ville',
    trainings: 'Formations',
    transferType: 'Type de transfert',
    transferTypeAirport: "Vers/depuis l'aéroport",
    transferTypeOutTown: 'En dehors de la ville',
    transferTypeShuttle: 'Navette',
    tripType: 'Type de voyage',
    trips: 'Voyages',
    tripsEV: 'Nº EV trips',
    tripsDieselPetrol: 'Nº Diesel/ Petrol trips',
    typeOfTrip: 'Type de voyage',
    unit: 'Unité',
    username: "Nom d'utilisateur",
    vehicle: 'Véhicule',
    vehicleTrackingSystem: 'Système de suivi des véhicules',
    vendorNumber: 'Numéro de vendeur',
    weekly: 'Hebdomadaire',
    when: 'Lorsque',
    year: 'An',
    yes: 'Oui',
  },
  crumbs: {
    bookingDetails: 'Les détails de réservation',
    bookings: 'Réservations',
    bookingList: 'Liste des réservations',
    countryReport: 'Rapport de pays',
    createAgencyInvoice: "Créer une facture d'agence",
    dashboard: 'Tableau de bord',
    explore: 'Explorer',
    facilityMenu: 'Menu des installations',
    home: 'Maison',
  },
  feedback: {
    btnConfirmRemark: 'Confirmer la remarque',
    btnForward: "Transférer au bureau d'administration",
    comment: 'commentaire',
    commentAndRemark: 'commentaire et remarque',
    close: 'Proche',
    closed: 'Fermée',
    confirmOrEditRemark: 'Confirmer ou modifier Remarque',
    dateOfRide: 'Date du trajet',
    forwardForAction: "En avant pour l'action",
    inputAdminEmail: "Adresse e-mail de l'administrateur",
    insertRemark: 'Insérer une remarque',
    msgExportError: 'Aucun retour pour cette période',
    pageTitle: 'Satisfaction client pour',
    pending: 'En attente',
    placeholderStatusClosed: 'Écrivez ici votre remarque sur le commentaire…',
    placeholderStatusForwarded: "Écrivez ici votre suggestion au bureau d'administration",
    placeholderStatusUnderProcess: 'Écrivez ici votre remarque sur le commentaire…',
    processing: 'Traitement',
    remarkedBy: 'Remarqué par',
    reviewed: 'Révisé',
    ride: 'balade',
    see: 'Voir',
    statusChanged: "L'état des commentaires a été modifié",
    suggestion: 'Suggestion',
    timePeriod: 'Période de temps',
  },
  invoice: {
    agencyDriver: "Chauffeur d'agence",
    bookedBy: 'Réservé par',
    charges: 'Des charges',
    comments: 'Commentaires',
    createInvoice: 'Créer une facture',
    departureTime: 'Heure de départ',
    fullTripDetails: 'Détails complets du voyage',
    greenFundIncluded: 'Fonds vert inclus',
    individualInvoiceCreatedTitle: 'La facture individuelle est créée et un e-mail sera envoyé',
    individualInvoiceFor: 'Facture individuelle pour',
    individualInvoiceListTitle: 'Sélectionnez Facture individuelle',
    inputInvoiceSearchByPaxAgency: 'Recherche par Agence Pax ou Passager',
    inputSearchByInvoiceNumber: 'Rechercher par numéro de facture',
    insertComment: 'Insérer un commentaire',
    invoiceCreated: 'Facture créée avec succès',
    invoiceCreatedModalGreeting: 'Vous créez une nouvelle facture',
    invoiceFor: 'Facture de',
    invoiceHistory: 'Historique des factures',
    invoiceListTitle: "Sélectionnez la réservation à partir de l'emplacement",
    invoiceNumber: 'Numéro de facture',
    invoiceTotal: 'Montant de la facture',
    issueDate: "Date d'émission",
    msgInvoiceUpdated: 'La facture a été mise à jour',
    msgDriveDeleted: 'Le lecteur a été supprimé',
    pageTitle: 'Veuillez remplir le formulaire pour les emplacements',
    passengerDetails: 'Détails du passager',
    paxNumber: 'Numéro de personne',
    quantity: 'Quantité',
    requestUnit: 'Unité de demande',
    sendCopy: 'Envoyer une copie à',
    totalAmount: 'Montant total',
    tripsDetails: 'Détails des voyages',
    viewInvoice: 'Voir la facture',
  },
  logbook: {
    btnFilters: 'Filtres',
  },
  mobility: {
    addDriver: 'Ajouter le pilote',
    addVehicle: 'Ajouter le véhicule',
    bloodGroup: 'Groupe sanguin',
    btnConnected: 'Liée',
    btnConnectToGPS: 'Se connecter au GPS',
    btnDisconnect: 'Déconnecter',
    btnGoToChecklist: 'Aller à la liste de contrôle',
    btnMarkAvailable: 'Marquer comme disponible',
    btnMarkUnavailable: 'Marquer comme indisponible',
    btnSaveSettings: 'Sauver les paramètres',
    carInfo: 'Informations sur la voiture',
    chassisNumber: 'Numéro de châssis',
    checklistCompletedByDriver: 'A partir de la check-list remplie par le conducteur',
    clear: 'Dégager',
    critical: 'Critique',
    criticalIssue: 'Problème critique',
    dailyChecks: 'Contrôles quotidiens',
    driverLicense: 'Permis de conduire',
    driversListTitle: 'Pilotes pour',
    editDriver: 'Modifier le pilote',
    editVehicle: 'Modifier le véhicule',
    expirationDate: "Date d'expiration",
    externalProvider: 'Fournisseur externe',
    filterByDriver: 'Filtrer par pilote',
    filterByPassengerEmail: 'Filtrer par e-mail du passager',
    filterByPassengerLastName: 'Filtrer par nom de famille du passager',
    filterByVehicle: 'Filtrer par véhicule',
    fleetCostsSettings: 'Paramètres des coûts de la flotte',
    forVehicle: 'Pour le véhicule',
    inputAverageKmPerHour: 'Moyenne km/h',
    inputBuffer: 'Amortir',
    inputCarpooling: 'Faire du covoiturage',
    inputCost: 'Coût',
    inputDiesel: 'Gazole/lt',
    inputDriverGrossSalary: 'Chauffeur brut',
    inputEmissionDiesel: 'Gazole kg CO2e/lt',
    inputEmissionPetrol: 'Essence kg CO2e/lt',
    inputGlobal: 'Mondiale',
    inputPerHour: 'Par heure',
    inputPerKmDiesel: 'Par km (Diesel)',
    inputPerKmPetrol: 'Par km (Essence)',
    inputPetrol: 'Essence/lt',
    issues: 'Problèmes',
    issuesTextVehicleCanStart:
      'Les problèmes suivants sont présents mais le véhicule peut démarrer un nouveau trajet',
    issuesTextBeforeStarting:
      "Les problèmes suivants sont présents et certains d'entre eux doivent être résolus avant de démarrer un nouveau lecteur",
    lastModified: 'Dernière modification le',
    location: 'Emplacement',
    msgDateError: "La date n'est pas valide. Rafraîchir la page, s'il vous plaît",
    msgDriveCancel: 'Le trajet a été annulé',
    msgDriveCostsUpdated: 'Coûts de conduite a été mis à jour',
    msgDrivePublished: 'Drive a été publié',
    msgDrivesPublished: 'Les lecteurs ont été publiés',
    msgDriverUpdated: 'Le pilote a été mis à jour avec succès',
    msgShareArmoured: "Il n'est pas possible de partager des véhicules blindés",
    msgUpdateDrive: 'Le lecteur a été mis à jour',
    msgVehicleCreated: 'Le véhicule a été créé avec succès',
    msgVehicleUpdated: 'Le véhicule a été mis à jour avec succès',
    mobilityLogbook: 'Carnet de mobilité',
    myDrivers: 'Mes pilotes',
    myVehicles: 'Mes véhicules',
    noCostSettings: 'Pas de paramètres de coût',
    notReported: 'Non reporté',
    notUsed: 'Non utilisé',
    oneDay: 'Un jour',
    otherIssues: 'autres issues',
    overviewFor: 'Aperçu pour',
    owned: 'Possédée',
    ownedBy: 'Propriété de',
    personalInfo: 'Informations personnelles',
    plateNumber: 'Numéro de la plaque',
    preferredDriver: 'Chauffeur préféré',
    preferredVehicle: 'Véhicule préféré',
    rented: 'Loué',
    roofNumber: 'Numéro de toit',
    selectDriver: 'Sélectionnez le pilote',
    selectVehicle: 'Sélectionnez un véhicule',
    selectWhen: 'Sélectionnez quand',
    selectWhy: 'Sélectionnez pourquoi et quand',
    serviceCostSettingsTitle: 'Paramètres de coût de service pour',
    shared: 'Partagé',
    skinType: 'Type de peau',
    statusExistMsg: 'pour ces dates avant de rendre indisponible',
    statusExistWarning: "n'est pas disponible pour ces dates",
    submitted: 'Soumise',
    totalKm: 'Kilométrage total',
    unavailable: 'Indisponible',
    vehicleModel: 'Modèle de véhicule',
    vehicleOwner: 'Propriétaire du véhicule',
    vehicleReasonRegularMaintenance: 'Maintenance régulière',
    vehicleReasonExtraordinaryRepair: 'Réparation extraordinaire',
    vehicleReasonForDisposal: 'Indisponible - Pour élimination',
    vehicleType: 'Type de véhicule',
    vehiclesChecklist: 'Vérification des véhicules',
    vehiclesListTitle: 'Véhicules pour',
    willBeDisabled: 'sera désactivé',
    willBeUnavailable: 'sera indisponible',
    willNotBeDisabled: 'ne peut pas être désactivé',
    willNotBeUnavailable: 'ne peut pas être indisponible',
  },
  planner: {
    addLeg: 'Ajouter une jambe',
    btnReject: 'Rejeter la réservation',
    btnTimeSlotEdit: 'Modifier le créneau horaire',
    commentDriver: 'Commentaire pour le pilote',
    commentPax: 'Commentaire pour le passager',
    createDrive: 'Créer un lecteur',
    driveReadOnly: 'Lecteur (lecture seule)',
    driveScheduledFor: 'Conduite prévue pour',
    driveStartedFor: 'Le variateur a été démarré et arrêté pendant',
    dropOff: 'Déposer',
    focalPoint: 'Point focal',
    justReadOnly: 'Juste en lecture seule',
    mainInfo: 'Informations principales',
    msgCancelDriveConfirm: 'Voulez-vous vraiment annuler trajet?',
    msgConflictDrives: 'Le conducteur sélectionné a déjà conduit avec un autre véhicule',
    msgConflictNotInShuttle:
      'Ces changements devraient être en dehors de la configuration de la navette',
    msgConflictingDrivesEdit:
      "Veuillez vérifier les dates et les heures pour vous assurer qu'elles sont correctement sélectionnées pour tous les voyages de cette réservation.",
    msgDriveStatus:
      'Utilisez le bouton Modifier le créneau horaire pour modifier l’heure de début et l’heure de fin du voyage. Cependant, les informations principales des passagers ne peuvent pas être modifiées',
    msgDriveVerified: 'Appuyez sur le bouton de confirmation pour poursuivre vos modifications',
    msgNoChanges: "il n'y a aucune modification à enregistrer",
    msgOverlappingDrives:
      'Les jambes soumises se chevauchent. Veuillez spécifier une date et une heure différentes',
    msgSharedDrive:
      'Il y a un lecteur existant sur le planificateur qui est en conflit avec les informations saisies. Veuillez spécifier une heure, un conducteur ou un véhicule différent',
    msgShuttleConfigUpdated: 'Shuttle Config a été mis à jour avec succès',
    multiWarn1: 'Un autre lecteur existe déjà pour le même créneau horaire',
    multiWarn2: 'si vous souhaitez attribuer un véhicule et un conducteur pour le trajet actuel ou',
    multiWarn3: 'pour rejeter',
    noDriverInformationAvailable: "Aucune information sur le conducteur n'est disponible",
    noPassengers: 'Aucun passager',
    numberOfPax: 'Nombre de personnes',
    pickUp: 'Récupérer',
    planned: 'Prévue',
    remarks: 'Remarques (facultatif)',
    returnTrip: 'Voyage de retour',
    dateStart: 'Date de début',
    dateEnd: 'Date de fin',
    titleExport: 'Exporter le mois en cours au format PDF',
    titleReject: 'By clicking on CONFIRM, the whole booking will be rejected',
    tripInfo: 'Trip Info',
    warnShuttleChange: 'Le type de transfert sera modifié en',
    warnShuttleConflict:
      "L'heure de prise en charge/retour sélectionnée est en dehors de l'heure de navette prévue",
    warnShuttleDriverConflict:
      'Le conducteur sélectionné est occupé avec un autre lecteur en ce moment',
    workflow: 'Flux de travail',
  },
  report: {
    agencyPaxVsNot: 'Agence pax vs non',
    autoFilled: 'Remplissage automatique. Veuillez modifier si nécessaire',
    carpooledTrip: 'Voyage en covoiturage',
    co2Saving: 'Économies de CO2',
    costRecoverySummary: 'Résumé recouvrement coûts',
    costRecoveryTitle: 'Détails du rapport pour',
    countryDemand: 'Demande du pays',
    countryReporting: 'Rapports par pays',
    distanceTraveled: 'Distance parcourue',
    durationAndDistance: 'Durée et Distance',
    editTripData: 'Modifier les données du voyage',
    efficiencySaving: "Gain d'efficacité",
    errorMsgDrive: 'Aucun lecteur avec cet identifiant trouvé',
    errorMsgDrives: 'Aucun lecteur trouvé',
    fleet: 'Flotte',
    inputApprovedBy: 'Approuvé par',
    inputCertifiedBy: 'Certifié par',
    inputOdometerEnd: 'Fin du compteur kilométrique',
    inputOdometerStart: 'Démarrage du compteur kilométrique',
    inputPreparedBy: 'Préparé par',
    inputSearch: 'Recherche par e-mail ou code de réf',
    isDriveInFuture: 'Vous ne pouvez modifier que les voyages passés',
    isManualEditedTooltip: 'Données de trajet saisies manuellement',
    isSyncedTooltip: 'Données de trajet du VTS',
    monthlyBookings: 'Réservations mensuelles',
    monthlyCostRecoveries: 'Recouvrement des coûts mensuels',
    monthlySummary: 'Sommaire mensuel',
    monthlyTrips: 'Voyages mensuels',
    monthlyTripsDistance: 'Distance des trajets mensuels',
    monthlyTripsDuration: 'Durée des trajets mensuels',
    noData: 'Pas de données disponibles',
    performedOn: 'Exécuté le',
    reportDetails: 'Détails du rapport',
    ridesharedTrip: 'Voyage en covoiturage',
    rideSharing: 'Covoiturage',
    summary: 'Résumé',
    totalMileage: 'Kilométrage total',
    totalTime: 'Temps total',
    upcomingTripsMsg: 'Voyages à venir ou déjà facturés, données de trajet non modifiables',
    workflows: 'Flux de travail',
  },
  travelRequest: {
    msgSubmitError: 'All required fields should be filled',
    submittedDate: 'Date',
    transportationBooking: 'Transportation Booking',
    tripEditTitle:
      'Please fill in the fields below to update the Security Clearance Request draft of your trip',
  },
};
