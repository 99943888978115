/* eslint-disable react/no-unstable-nested-components */
import { useTranslation } from 'react-i18next';
import { Form } from 'informed';
import { TextField } from '@ui-modules/informed';
import { maskPercentage, maskSalary, percentageField } from '@common/utils';
import { IDriveCosts } from '@common/interfaces';
import './styles.scss';

interface CostFieldProps {
  className?: string;
  disabled?: boolean;
  label: string;
  name: keyof IDriveCosts;
  type?: string;
  mask?: (value: string) => string;
  validate?: ((value: unknown, values: Record<string, unknown>) => unknown) | undefined;
}

interface DriveCostsProps {
  data: IDriveCosts;
}

const DriveCosts = ({ data }: DriveCostsProps) => {
  const { t } = useTranslation();

  const CostField = ({
    className,
    disabled,
    label,
    name,
    type,
    mask,
    validate,
    ...rest
  }: CostFieldProps) => (
    <div className={className ? `field ${className}` : 'field'}>
      <TextField
        disabled={disabled}
        label={label}
        name={name}
        initialValue={`${data[name]}`}
        type={type}
        validateOnBlur
        mask={mask}
        validate={validate}
        {...rest}
      />
    </div>
  );

  return (
    <Form className="drive-costs-form">
      <CostField
        className="field-currency"
        data-testid="drive-cost-salary"
        disabled
        label={t('mobility.inputDriverGrossSalary')}
        name="grossSalary"
        mask={(value) => maskSalary(value as string)}
      />
      <CostField
        className="field-currency"
        data-testid="drive-cost-diesel-lt"
        disabled
        label={t('mobility.inputDiesel')}
        name="dieselFuelCost"
      />
      <CostField
        className="field-currency"
        data-testid="drive-cost-petrol-lt"
        disabled
        label={t('mobility.inputPetrol')}
        name="petrolFuelCost"
      />
      <CostField
        className="field-currency"
        data-testid="drive-cost-ev-lt"
        disabled
        label="Electric/kWh"
        name="evFuelCost"
      />
      <CostField
        disabled
        label={`${t('mobility.inputGlobal')} %`}
        name="basicPercentage"
        type="number"
        mask={(value) => maskPercentage(value as string)}
        validate={(value) => percentageField(value as string)}
      />
      <CostField
        disabled
        label={`${t('mobility.inputCarpooling')} %`}
        name="carpoolingPercentage"
        type="number"
        mask={(value) => maskPercentage(value as string)}
        validate={(value) => percentageField(value as string)}
      />
      <CostField
        disabled
        label={`${t('report.rideSharing')} %`}
        name="rideSharingPercentage"
        type="number"
        mask={(value) => maskPercentage(value as string)}
        validate={(value) => percentageField(value as string)}
      />
      <CostField
        disabled
        label={`${t('mobility.inputBuffer')} %`}
        name="percentageOfBuffer"
        type="number"
      />
      <CostField
        disabled
        label={t('mobility.inputAverageKmPerHour')}
        name="averageKmPerHour"
        type="number"
      />
      <CostField
        disabled
        label={t('mobility.inputEmissionDiesel')}
        name="kgCo2EmissionDiesel"
        type="number"
      />
      <CostField
        disabled
        label={t('mobility.inputEmissionPetrol')}
        name="kgCo2EmissionPetrol"
        type="number"
      />
      <CostField
        className="field-currency"
        disabled
        label={t('mobility.inputPerHour')}
        name="notEvPerHour"
        type="number"
      />
      <CostField
        className="field-currency"
        disabled
        label="Per Hour (Electric)"
        name="evPerHour"
        type="number"
      />
      <CostField
        className="field-currency"
        disabled
        label={t('mobility.inputPerKmDiesel')}
        name="perKmDiesel"
        type="number"
      />
      <CostField
        className="field-currency"
        disabled
        label={t('mobility.inputPerKmPetrol')}
        name="perKmPetrol"
        type="number"
      />
      <CostField
        className="field-currency"
        disabled
        label="Per Km (Electric)"
        name="perKmEv"
        type="number"
      />
    </Form>
  );
};

export default DriveCosts;
